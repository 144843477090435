module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LatteCarousel","short_name":"Latte","start_url":"/","background_color":"#f5e0d1","theme_color":"#f5e0d1","display":"minimal-ui","icon":"src/images/inverted_small.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
